<script lang="ts" context="module">
    import { goto } from '$app/navigation'
    import { getAddDaysUTC } from 'helpers/date.helper'
    import { runQueryWithPromise } from 'helpers/graphql-query'
    import { querySubscription } from 'helpers/graphql-ws'
    import { tap } from 'rxjs'
    import { get } from 'svelte/store'
    import { activeSubscription, auctionsNoSubscription, minAuctionNumber, auctionsSubscription } from 'stores/auction.store'
    import {
        AuctionsSubscriptionDocument,
        AuctionUpcomingAllDocument,
        AuctionByIdDocument,
        ListingsByAuctionIdDocument,
        AuctionsPastDocument,
    } from '_config/graphql/tags-generated'

    export async function getAuctionById(auctionId: number, client: GraphqlClient) {
        const result = await runQueryWithPromise(AuctionByIdDocument, { id: auctionId } as SomeObject, client)
        if (result?.error) goto(`/error#auctionById`)

        return result?.data?.auctionById as AuctionDetailResponse
    }

    export async function getListingByAuctionId(auctionId: number, client: GraphqlClient) {
        const result = await runQueryWithPromise(ListingsByAuctionIdDocument, { id: auctionId as number } as SomeObject, client)
        if (result?.error) goto(`/error#listById`)

        return result?.data?.listingsByAuctionId as LotsResponse
    }

    export async function getPastAuctions(auctionFilter: AuctionFilter, client: GraphqlClient) {
        const result = await runQueryWithPromise(AuctionsPastDocument, auctionFilter as SomeObject, client)

        if (result?.error) goto(`/error#pastAuctions`)

        let auctions: AuctionResponse[] = result?.data?.pastAuctions?.auctions as AuctionResponse[]

        if (auctions) {
            auctionsNoSubscription.set(
                auctions.map((auction: AuctionResponse) => {
                    return {
                        id: auction.id,
                        title: auction?.title,
                        auctionStatus: auction.status,
                        startsAt: auction.startsAt,
                        numberOfLots: auction?.numberOfLots,
                        activityStatus: auction.activityStatus,
                        auctionFormat: auction?.auctionFormat,
                        auctionFormatLabel: auction?.auctionFormatLabel,
                        stockTypes: auction?.stockTypes,
                        bidAccounts: auction?.bidAccounts,
                    } as UpcomingAuction
                })
            )
        }
    }

    export async function getUpcomingAuctions(auctionFilter: AuctionFilter, client: GraphqlClient) {
        const result = await runQueryWithPromise(AuctionUpcomingAllDocument, auctionFilter as SomeObject, client)

        if (result?.error) goto(`/error#upcomingAuctions`)

        let auctions: AuctionResponse[] = result?.data?.auctions?.auctions as AuctionResponse[]

        getAuctionListSubscription(auctions)
    }

    export async function getAuctionForHomepage(auctionFilter: AuctionFilter, client: GraphqlClient) {
        const result = await runQueryWithPromise(AuctionUpcomingAllDocument, auctionFilter as SomeObject, client)

        if (result?.error) goto(`/error#homepageAuction`)

        const initialAuctions = result?.data?.auctions?.auctions as AuctionResponse[]

        if (initialAuctions != undefined) {
            if (initialAuctions?.length < get(minAuctionNumber)) {
                secondAuctionsRequest(initialAuctions, client)
            } else {
                let auctions: AuctionResponse[] = [...initialAuctions]

                getAuctionListSubscription(auctions)
            }
        }
    }

    async function secondAuctionsRequest(initialAuctions: AuctionResponse[], client: GraphqlClient) {
        let auctions: AuctionResponse[]
        if (initialAuctions != undefined) {
            auctions = [...initialAuctions]

            const diff = get(minAuctionNumber) - initialAuctions?.length

            const variables = {
                from: getAddDaysUTC(2).toISOString(),
                until: '',
                pageSize: diff,
            } as AuctionFilter

            const result = await runQueryWithPromise(AuctionUpcomingAllDocument, variables as SomeObject, client)

            let additionalAuctions = result?.data?.auctions?.auctions as AuctionResponse[]
            if (additionalAuctions && additionalAuctions.length > 0) {
                auctions = [...auctions, ...additionalAuctions]
            }
        } else {
            auctions = []
        }

        getAuctionListSubscription(auctions)
    }

    async function getAuctionListSubscription(auctions: AuctionResponse[]) {
        const subscribeAuctionIds = auctions?.map(auction => auction.id)

        activeSubscription.set(
            await querySubscription(AuctionsSubscriptionDocument.loc!.source.body, {
                ids: subscribeAuctionIds,
            })
                .pipe(
                    tap((data: any) => {
                        setAuctionsSubscription(data, auctions)
                    })
                )
                .subscribe()
        )
    }

    function setAuctionsSubscription(auctionListSubscription: SomeObject, auctions: AuctionResponse[]) {
        auctionsSubscription.set(
            auctionListSubscription?.data.auction.map((auction: Auction) => {
                if (!auctions) return

                const auctionDetail = auctions?.find(a => a.id == auction.id)

                return {
                    id: auction.id,
                    title: auctionDetail?.title,
                    auctionStatus: auction.auctionstatus,
                    startsAt: auction.startsat,
                    numberOfLots: auctionDetail?.numberOfLots,
                    activityStatus: auction.activitystatus,
                    auctionFormat: auctionDetail?.auctionFormat,
                    auctionFormatLabel: auctionDetail?.auctionFormatLabel,
                    stockTypes: auctionDetail?.stockTypes,
                    bidAccounts: auctionDetail?.bidAccounts,
                    isPrivateAuctionWatcher: auctionDetail?.isPrivateAuctionWatcher,
                } as UpcomingAuction
            })
        )
    }
</script>
