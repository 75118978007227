import { Observable } from 'rxjs'
import { webSocketClient } from 'stores/ws-client.store'
import { get } from 'svelte/store'

// get the gql parameter from FooDocument.loc!.source.body
export function querySubscription(gql: any, variables: any) {
    let client = get(webSocketClient)

    if (!client) throw new Error('!!! need to define ws client !!!')

    return new Observable(function subscribe(subscriber) {
        client.subscribe(
            {
                query: gql,
                variables: variables,
            },
            {
                next: (data: any) => subscriber.next(data),
                error: (err: any) => subscriber.error(err),
                complete: () => subscriber.complete(),
            }
        )
    })
}
